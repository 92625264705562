* {
  box-sizing: border-box;
}
html {
  scroll-padding-top: 120px;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Quicksand", sans-serif !important;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar:horizontal {
  height: 6px;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:horizontal {
  background: #c7c7c7;
  border-radius: 6px;
}

.title-font {
  font-family: "Fredoka", sans-serif;
  font-weight: 700;
}

.container {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: 768px) {
    padding: 0;
  }
}

.mainGrid {
  display: Grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 20;
  @media (max-width: 768px) {
    display: block;
  }
}

.colSpanTab {
  position: sticky;
  top: 220px;
  grid-column: span 2 / span 2;
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 1024px) {
    grid-column: span 3 / span 3;
  }
}
.mainSerchContent {
  grid-column: span 6 / span 6;

  @media (max-width: 1024px) {
    grid-column: span 5 / span 5;
  }

  @media (max-width: 768px) {
    grid-column: span 7 / span 7;
  }
}

.link {
  color: #3aa52a;
  text-decoration: none;
}
