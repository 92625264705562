/* Scroll list styles */
.scroll-list {
  margin-top: 20px;
  position: relative;
  height: 40px; /* Fixed height for viewport */
  overflow: hidden;
}

.scroll-item {
  height: 40px;
  font-size: 1.1rem;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: scroll-animation 30s forwards;
}

/* Keyframes for scroll animation */
@keyframes scroll-animation {
  0% {
    transform: translateY(100%);
  }
  9% {
    transform: translateY(100%);
  }
  10% {
    transform: translateY(0);
  }
  29% {
    transform: translateY(-0%);
  }
  30% {
    transform: translateY(-100%);
  }
  49% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(-200%);
  }
  63% {
    transform: translateY(-200%);
  }
  64% {
    transform: translateY(-300%);
  }
  99% {
    transform: translateY(-300%);
  }
  100% {
    transform: translateY(-400%);
  }
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.saving span {
  margin-left: 2px;
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.saving span:nth-child(2) {
  animation-delay: 0.2s;
}

.saving span:nth-child(3) {
  animation-delay: 0.4s;
}
